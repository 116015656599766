import React from 'react';
import Contact from './Contact';
import Intro from './Intro';
import Projects from './Projects';
import TechStack from './TechStack';

function Main() {
  return (
    <div>
      <Intro/>
      <Projects/>
      <TechStack/>
      <Contact/>
    </div>
  )
}

export default Main