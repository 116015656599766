import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

function Header() {

  const [headerTextPos, setheaderTextPos] = useState('');
  const [totalScrolledDistance_s, setTotalScrolledDistance_s] = useState(0)

  let initialWindowPosition = window.scrollY;

  function handleScroll() {
    const currentScrollPosition = window.scrollY;
    const totalScrolledDistance = Math.abs(currentScrollPosition - initialWindowPosition);
    setTotalScrolledDistance_s(Math.abs(currentScrollPosition - initialWindowPosition));
    setheaderTextPos(totalScrolledDistance)
    return totalScrolledDistance;
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    setheaderTextPos(totalScrolledDistance_s)
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); 

  return (
    <Div>
      <div className='title'>
        <h1>Hi!<span>My name is Nienke</span></h1>
      </div>
      <div className='header-img'>
        <span style={{ top: 48 + headerTextPos*0.35 + 'px' }}>Hi!</span>
        <span style={{ top: 48 + headerTextPos*-0.25 + 'px' }}>Hi!</span>
        <span style={{ top: 465 + headerTextPos*0.20 + 'px' }}>Hi!</span>
      </div>
    </Div>
  )
}

const Div = styled.div`
    margin: 4vw auto 10vw;
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
    z-index: 1;
  
    .title {
      position: absolute;
      left: 50%;
      top: 200px;
      transform: translateX(-176%);

      h1 {
        span {
          font-size: 48px;
          display: block;
          margin-left: 7px;
          font-family: 'Zighead', sans-serif;
          font-weight: normal;

          @media (max-width: 1440px) {
            font-size: 32px;
          }

          @media (max-width: 1024px) {
            font-size: 48px;
            margin-left: 0;
          }

          @media (max-width: 768px) {
            font-size: 32px;
            margin-top: 12px;
          }
        }
      }

      @media (max-width: 1800px) {
        top: 34.9%;
        left: 24.2%;
        transform: translate(-50%,-50%);
      }
      
      @media (max-width: 1024px) {
        top: 32px;
        position: relative;
        transform: translateX(-50%);
        left: 50%;
        text-align: center;
        margin-bottom: 48px;
      }            
    }

    .header-img {
      width: 100%;
      height: 825px;
      background-image: url('/Images/header-photo__desktop.png');
      background-repeat: no-repeat;
      background-position: center;
      margin-left:auto;
      margin-right:auto;
      background-size: 1100px;

      span {
        display: block;
        position: absolute;
        font-family: 'Zighead', sans-serif;
        font-weight: normal;
        line-height: 1;
        color: #FDF7F9;
        background-repeat: no-repeat;
        background-position: center;
        left: 50%;
      
        &:nth-of-type(1) {
          font-size: 950px;
          z-index: -1;
          background-size: 1100px;
          top: 48px;
          left: 50%;
          transform: translateX(-129%);
        }

        &:nth-of-type(2) {
          font-size: 550px;
          background-size: 1100px;
          top: 48px;
          left: 50%;
          transform: translateX(40%);
        }

        &:nth-of-type(3) {
          font-size: 455px;
          z-index: -1;
          background-size: 1100px;
          top: 465px;
          left: 50%;
          transform: translateX(100%);
        }
      }

      @media (max-width: 1800px) {
        background-size: 61.15%;
        height: 45.75vw;

        span {
          background-repeat: no-repeat;
          background-position: center;
          left: 50%;
        
          &:nth-of-type(1) {
            font-size: 52.75vw;
          }

          &:nth-of-type(2) {
            font-size: 30.55vw;
          }

          &:nth-of-type(3) {
            font-size: 25.25vw;
            margin-top: calc(20.4vw + -168px + 168px * (100 - 1025) / (1799 - 1025));
          }
        }
      }

      @media (max-width: 1024px) {
        span {
        
          &:nth-of-type(1) {
            margin-top: 24vw;
            transform: translateX(-135%);
          }

          &:nth-of-type(2) {
            margin-top: 25vw;
            transform: translateX(52%);
          }

          &:nth-of-type(3) {
            transform: translateX(89%);
            margin-top: calc(43vw + -168px + 168px * (100 - 1025) / (1799 - 1025));
          }
        }
      }

      @media (max-width: 1024px) {
        background-size: 75%;
        height: 60vw;
      }

      @media (max-width: 768px) {
        background-image: url('/Images/header-photo__mobile.png');
      }
    }

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  @media (max-width: 768px) {
    margin: 2vw 0 98px;
  }
`


export default Header