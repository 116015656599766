import React from 'react';
import styled from 'styled-components';

function FormSuccess(props) {

    const [showFormSuccess, setShowFormSuccess] = React.useState(false);

    function hideFormSuccess() {
        setShowFormSuccess(true);
    }

    return (
        <Div className={showFormSuccess ? 'hide' : ''} onClick={(e) => {
            if (e.target === e.currentTarget) {
                hideFormSuccess()
            }}}>
            <div>
                <p className='title'>Thank you {props.name}!</p>
                <p>I'll be in touch soon.</p>
                <span onClick={hideFormSuccess}></span>
            </div>
        </Div>
    )
}

const Div = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #00000036;
    z-index: 1;

    &.hide {
        display: none;
    }

    div {
        width: 500px;
        background-color: #d3ffdb;
        padding: 48px;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;

        p:nth-of-type(1) {
            font-size: 32px;
            margin-bottom: 16px;
        }

        img {
            width: 160px;
        }

        span {
            position: absolute;
            right: 18px;
            top: 20px;
            width: 32px;
            height: 30px;
            opacity: 0.6;
            cursor: pointer;
            
            &:hover {
                opacity: 1;
            }

            &:before, &:after {
                position: absolute;
                left: 15px;
                content: ' ';
                height: 30px;
                width: 3px;
                background-color: #70545E;
            }

            &:before {
                transform: rotate(45deg);
            }

            &:after {
                transform: rotate(-45deg);
            }
        }

        @media (max-width: 768px) {
            width: 100%;
            margin: 0 16px;
        }
    }
`

export default FormSuccess