import React from 'react';
import styled from 'styled-components';

function Intro() {
  return (
    <Div>
      <h2>A few notes about me</h2>
      <p>Front-end developer with a proven track record, originally from the Netherlands and currently based in Scotland. Passionate about leveraging the limitless possibilities of front-end development. I have consistently pushed boundaries and delivered innovative projects that highlight my dedication to quality and creativity.</p>
    </Div>
  )
}

const Div = styled.div`
    max-width: 600px;
    margin: 0 auto 10vw;
    padding: 0 16px;
    text-align: center;
    position: relative;
    z-index: 1;

    @media (max-width: 768px) {
      margin-bottom: 98px;
    }
`

export default Intro