import React from 'react';
import styled from 'styled-components';

function TechStack() {
    return (
        <Div>
            <h2>Tech stack</h2>
            <div>
                <span>React</span>
                <span>Git</span>
                <span>Docker</span>
                <span>Javascript</span>
                <span>jQuery</span>
                <span>Magento</span>
                <span>HTML</span>
                <span>CSS</span>
                <span>SCSS</span>
                <span>Wordpress</span>
            </div>
        </Div>
    )
}

const Div = styled.div`
    margin: 0 auto 10vw;
    padding: 0 16px;
    text-align: center;
    position: relative;
    z-index: 1;

    div {
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        /* gap: 20px 18px; */
        justify-content: space-around;
        align-content: space-around;
        max-width: 880px;
        height: 156px;

        span {
            width: 160px;
            height: 60px;
            padding: 16px 0;
            border-radius: 50px;
            border: 1px solid #70545E;
            background-color: #f2eff1;

            @media (max-width: 1440px) {
                padding: 18px 0 16px;
            }
        }

        @media (max-width: 1280px){
            max-width: 524px;
            height: 312px;
        }

        @media (max-width: 550px){
            height: 348px;
            justify-content: space-between;

            span {
                height: 52px;
                width: 48.5%;
                padding: 14px 0 16px;
            }
        }
    }

    @media (max-width: 768px) {
        margin-bottom: 98px;
    }
`

export default TechStack