import React from 'react'
import styled from 'styled-components'

function Footer() {
  return (
    <Div>
      <img className='img-background' src="/Images/background__footer.svg" alt="" />
      <div className="horizontal-scroll__container">
        <div className="horizontal-scroll">
          <span className='title'>COME & SAY HELLO</span>
          <span className='title'>COME & SAY HELLO</span>
        </div>
      </div>
      <div className='contact-details'>
        <div>
          <p className='title'>Call me why don't you</p>
          <a href='tel:+447521481715'>+44 7521 4817 15</a>
        </div>
        <div>
          <p className='title'>EMail the 90's out of me</p>
          <a href='mailto:nienke.dalstra@gmail.com'>nienke.dalstra@gmail.com</a>
        </div>
        <div>
          <p className='title'>Vist my LinkedIn</p>
          <a href='https://www.linkedin.com/in/nienke-dalstra-12166353/'>LinkedIn</a>
        </div>
      </div>
    </Div>
  )
}

const Div = styled.div`
  position: relative;

  .img-background {
    display: block;
    position: absolute;
    width: 2500px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    @media (max-width: 1900px) {
      width: 2300px;
    }
  }

  .horizontal-scroll__container {
    position: absolute;
    bottom: 0;
    width: 100vw;
    overflow: hidden;

    .horizontal-scroll {
      overflow: hidden;
      white-space: nowrap;
      animation: scrollText 25s infinite linear;
      display: inline-block;

      span {
        font-size: 550px;
        display: inline-block;
        min-width: 100vw;
        padding-left: 180px;
        line-height: 1.4;
        color: #f2eff1;
      }
    }

    @media (max-width: 768px) {
      bottom: 110px;

      .horizontal-scroll {
        animation-duration: 15s;

        span {
          font-size: 300px;
        }
      }
    }
  }


  @keyframes scrollText {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(-50%);
    }
  }

  .contact-details {
    position: relative;
    min-width: 860px;
    width: 60%;
    border-radius: 100px 100px 0 0;
    background-color: #ECE1E5;  
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding: 48px 0;

    div {

      &:nth-of-type(2) {
        padding-left: 48px;
        padding-right: 48px;

        @media (max-width: 1024px) {
          padding-left: 0;
          padding-right: 0;
        }
      }

      p {
        font-size: 20px;
      }
    }

    @media (max-width: 1024px) {
      border-radius: 80px 80px 0 0;
      min-width: auto;
      width: 500px;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 32px 0;

      div:nth-of-type(1),
      div:nth-of-type(2) {
        padding-bottom: 24px;
      }
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    padding: 0 16px;
  }
`

export default Footer