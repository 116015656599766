import React from 'react';
import styled from 'styled-components';
import ProjectCard from './ProjectCard';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProjectData from '../Assets/Data/ProjectData';

import Video from './Video';

function Projects() {
  const sliderSettings = {
    infinite: true,
    speed: 400,
    slidesToScroll: 1,
    variableWidth: true,
    centerMode: true
  };

  const [videoClassName, setVideoClassName] = React.useState('hide');

  function updateVideoState(target){
    if (target === 'video') {
      setVideoClassName('hide');
    } else {
      setVideoClassName('');
    }
  }

  return (
    <Div>
      <div className='img-background__container'>
        <img className='img-background' src='/Images/background__main.svg' alt="" />
      </div>
      <h2>Projects</h2>
      <Slider {...sliderSettings}>
        {ProjectData.map((projectData) => {
          return (
            <ProjectCard 
              key={projectData.id}
              title={projectData.title}
              description={projectData.description}
              date={projectData.date}
              type={projectData.type}
              upcoming={projectData.upcoming}
              link={projectData.link}
              letter={projectData.letter}
              video={projectData.video && projectData.video}
              updateVideoState={updateVideoState}
            />
          )
        })}
      </Slider>   
      {ProjectData.map((projectData) => {
        return (
          <Video
            key={projectData.id}
            video={projectData.video && projectData.video}
            updateVideoState={updateVideoState}
            videoClassName={videoClassName}
          />
        )
      })}
    </Div>
  )
}

const Div = styled.div`
    position: relative;
    margin: 0 auto 12vw;
    text-align: center;

    h2 {
      position: relative;
    }

    .img-background__container {
      position: absolute;
      left: 50%;
      top: 65%;    
      transform-origin: center;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      width: 1200px;
      height: 1200px;

      .img-background {
        width: 100%;
      }

      @media (max-width: 1440px) {
        width: 900px;
        height: 900px;
      }

      @media (max-width: 768px) {
        width: 800px;
        height: 800px;
        top: 60%;
      }
    }

    .img-background {
      width: 100%;
      animation: backgroundImgAnimMain 90s infinite linear;
    }

    @keyframes backgroundImgAnimMain {
      0%{transform: rotate(0deg)}
      100%{transform: rotate(360deg)}
    }

    .slick-list {
      /* border: 1px solid red; */
      height: 595px;

      @media (max-width: 1440px) {
        height: 545px;
      }

      @media (max-width: 1024px) {
        height: 480px;
      }

      @media (max-width: 768px) {
        height: 510px;
      }

      @media (max-width: 480px) {
        height: 570px;
      }

      @media (max-width: 380px) {
        height: 550px;
      }

      @media (max-width: 330px) {
        height: 560px;
      }
    }

    .slick-slider {
      margin: 0 auto;
      width: 860px;
      height: 545px;

      @media (max-width: 1440px) {
        width: 650px;
        height: 495px;
      }

      @media (max-width: 1024px) {
        height: 430px;
      }

      @media (max-width: 768px) {
        width: 470px;
        height: 460px;
      }

      @media (max-width: 480px) {
        width: 380px;
        height: 520px;
      }

      @media (max-width: 380px) {
        width: 330px;
        height: 500px;
      }

      @media (max-width: 330px) {
        width: 320px;
        height: 510px;
      }

      .slick-arrow {
        position: absolute;
        top: 0;
        left: 50%;
        right: auto;
        transform: translate(-50%, -50%);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 50%;
        width: 126px;
        height: 28px;
        z-index: 1;
        transition: left 0.05s ease-in-out;

        &::before,
        &::after {
          content: none;
        }

        &.slick-prev {
          background-image: url(/Images/arrow__prev.svg);
          margin-left: -90px;

          @media (min-width: 1024px) {
            &:hover {
              left: 48%;
              transition: left 0.2s ease-in-out;
            }
          }

          @media (max-width: 768px) {
            background-image: url(/Images/arrow__prev__small.svg);
            width: 90px;
            margin-left: -155px;
          }

          @media (max-width: 480px) {
            margin-left: -120px;
          }

          @media (max-width: 380px) {
            margin-left: -90px;
          }

          @media (max-width: 330px) {
            margin-left: -80px;
          }
        }

        &.slick-next {
          background-image: url(/Images/arrow__next.svg);
          margin-left: 90px;
          

          @media (min-width: 1024px) {
            &:hover {
              transition: left 0.2s ease-in-out;
              left: 52%;
            }
          }

          @media (max-width: 768px) {
            background-image: url(/Images/arrow__next__small.svg);
            width: 90px;
            margin-left: 155px;
          }

          @media (max-width: 480px) {
            margin-left: 120px;
          }

          @media (max-width: 380px) {
            margin-left: 90px;
          }

          @media (max-width: 330px) {
            margin-left: 80px;
          }
        }
      }

      .slick-slide {
        padding: 72px 40px;

        @media (max-width: 1024px) { 
          padding: 38px 16px;
        }
      }
    }

  @media (max-width: 768px) {
    margin-bottom: 124px;
  }
`

export default Projects