import React from 'react';
import styled from 'styled-components';

function Video(props) {

    function showVideo(){
        if(props.video) {
            return (
                <Div className={props.videoClassName} onClick={(e) => {
                    if (e.target === e.currentTarget) {
                        props.updateVideoState('video');
                    }
                }}>
                    <div className='video-content'>
                        <p className='title'>Banner Generator</p>
                        <p>Visual demonstration on creating onsite banners with the banner generator</p>
                        <video src={'/Images' + props.video} controls />
                        <span onClick = {(e) => {
                            props.updateVideoState('video');
                            }}> 
                        </span>
                    </div>
                </Div>
            )
        } else {
            return null;
        }
    }

    return (
        showVideo()
    )
}

const Div = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #00000036;
    z-index: 2;

    &.hide {
        display: none;
    }

    .video-content {
        width: 36vw;
        background-color: #F6F3F4;
        padding: 32px;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;

        .title {
            font-size: 32px;
        }

        video {
            width: 100%;
            border-radius: 16px;
            border-bottom: 1px solid #fff;
            margin-top: 16px;

            &::-webkit-media-controls-panel {
                background-image: none !important;
                background: rgb(255,255,255) !important;
                background: linear-gradient(0deg, rgba(112,84,94,0.5) 0%, rgba(255,255,255,0) 20%) !important;
            }
        }

        span {
            position: absolute;
            right: 18px;
            top: 20px;
            width: 32px;
            height: 30px;
            opacity: 0.6;
            cursor: pointer;

            &:hover {
                opacity: 1;
            }

            &:before, &:after {
                position: absolute;
                left: 15px;
                content: ' ';
                height: 30px;
                width: 3px;
                background-color: #70545E;
            }

            &:before {
                transform: rotate(45deg);
            }

            &:after {
                transform: rotate(-45deg);
            }
        }


        @media (max-width: 1440px) {
            width: 500px;
            border-radius: 30px;
        }

        @media (max-width: 640px) {
            width: 89.75%;
        }
    }
`

export default Video