import React from 'react';
import styled from 'styled-components';
import { send } from 'emailjs-com';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import FormSuccess from './FormSuccess';

function Contact() {

    const [formSuccess, setFormSuccess] = React.useState(false);

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            message: ''
        },

        // validation
        validationSchema: Yup.object({
            name: Yup.string().required('Name is required'),
            email: Yup.string().email('Please provide your email address').required('Email is required'),
            message: Yup.string().required('Message is required')
        }),

        onSubmit: (values) => {
            setFormSuccess(true);
            send(
                'service_fbs76sn', // service id
                'template_bivj4pm', // template id
                values,
                'NE_9bt6J_zPSg6GDy' // public key
            )
                .then((response) => {
                    console.log(response.status, response.text);
                })
                .catch((err) => {
                    console.log(err);
                });
            }
    })
    
    return (
        <Div>
            <h2>Contact me</h2>

            <form onSubmit={formik.handleSubmit} autoComplete="on">
                <label className={formik.errors.name ? 'error' : ''} htmlFor="name" >
                    {formik.touched.name && formik.errors.name ? formik.errors.name : ''}
                </label>
                <input
                    type='text'
                    id='name'
                    name='name'
                    placeholder='Name'
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoComplete="on"
                />
                <label className={formik.errors.email ? 'error' : ''} htmlFor="email">
                    {formik.touched.email && formik.errors.email ? formik.errors.email : ''}
                </label>
                <input
                    type='email'
                    id='email'
                    name='email'
                    placeholder='Your email'
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoComplete="on"
                />
                <label className={formik.errors.message ? 'error' : ''} htmlFor="message">
                    {formik.touched.message && formik.errors.message ? formik.errors.message : ''}
                </label>
                <textarea
                    type='text'
                    id='message'
                    name='message'
                    placeholder='Message'
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoComplete="on"
                />
                <button disabled={!formik.isValid ? true : false} type='submit' onClick={() => setFormSuccess(false)}>Submit</button>
            </form>
            {formSuccess ? <FormSuccess name={formik.values.name}/> : null}
            
        </Div>
    )
}

const Div = styled.div`
    margin: 0 auto 10vw;
    padding: 0 16px;
    text-align: center;
    position: relative;
    z-index: 1;

    form {
        max-width: 500px;
        display: inline-block;

        label.error {
            color: #df0000;
            font-size: 14px;
            display: block;
            padding-bottom: 4px;
            margin-top: -4px;
        }

        input, textarea {
            width: 100%;
            min-width: 500px;
            max-width: 500px;
            background-color: #f2eff1;
            border: 1px solid #70545E;
            padding: 16px 24px;
            border-radius: 50px;
            margin-bottom: 24px;
            font-family: 'Poppins', sans-serif;
            font-size: 18px;
            line-height: 150%;
            outline: 0;
            color: #70545E;
            -ms-overflow-style: none; 
            scrollbar-width: none;  

            &::placeholder {
                color: #70545E;
            }

            &:-webkit-autofill,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:active {
                -webkit-box-shadow: 0 0 0 44px #f2eff1 inset;
                -webkit-text-fill-color: #70545E;
            }

            &::-webkit-scrollbar {
                display: none;
            }
        }

        textarea {
            resize: none;
            height: 200px;
            padding: 20px 24px;
        }

        button {
            background-color: #70545E;
            color: #f2eff1;
            padding: 16px 32px;
            font-size: 18px;
            line-height: 150%;
            outline: 0;
            border: none;
            border-radius: 50px;
            cursor: pointer;
            transition: background-color 0.2s ease-in-out;

            &:hover {
                background-color: #010c21;
            }

            &:disabled {
                background-color: #ada8aa;
            }
        }

        @media (max-width: 550px) {
            max-width: 100%;

            input, textarea {
                min-width: auto;
                max-width: 100%;
            }
        }
    }

    @media (max-width: 768px) {
        margin-bottom: 98px;
    }
`

export default Contact